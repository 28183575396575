'use client';

import { Honeybadger } from '@honeybadger-io/react';
import { useEffect } from 'react';
import styled from 'styled-components';

const ErrorPage = styled.main`
  min-height: 20vh;
  text-align: center;
  font-size: 2rem;
`;

export default function Error({ error, reset }: { error: Error; reset: () => void }) {
  useEffect(() => {
    Honeybadger.notify(error);
    console.error(error);
  }, []);

  return <ErrorPage>Oops! Something went wrong.</ErrorPage>;
}
